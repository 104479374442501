// 色の設定
.column-color1 {
  a, .line_dots::after {
    color : $primary-color;
  }
  .bg-column {
    background: $primary-color;
  }
  .bottom-title {
    background: #FFE94E;
    color: $primary-color;
  }
  .post-adv a{
    color: #FFE94E;
  }
  .color-theme {
    color: $primary-color;
  }
}

.column-color2 {
  a, .line_dots::after {
    color : #32246e;
  }
  .bg-column {
    background: $color-anata2;
  }
  .bottom-title {
    background: #32246e;
    color: white;
  }
  .post-adv a{
    color: #32246e;
  }
  .color-theme {
    color: #32246e;
  }

}

.column-color3 {
  a {
    color: #008405;
  }
  .line_dots::after {
    color : #008405;
  }
  .bg-column {
    background-color: #FFE45C;
    background-image: url('../img/tokyo-meetup-background.png');
    @include breakpoint(small only) {
      background-image: url('../img/tokyo-meetup-background-min.png');
    }
  }
  .top-image {
    background: #ffe45c;
  }
  .bottom-title {
    background: #008405;
    color: white;
  }
  .post-adv a{
    color: #008405;
  }
  .color-theme {
    color: #008405;
  }
  .bg-theme {
    background: #008405;
  }
}

.column-color4 {
  a {
    color: #FF7E69;
  }
  .line_dots::after {
    color : #FF7E69;
  }
  .bg-column {
    background-color: #FF7E69;
  }
  .bottom-title {
    background: #C8FEEB;
  }
  .post-adv a{
    color: #C8FEEB;
  }
  .color-theme {
    color: #FF7E69;
  }
  .color-theme2, .color-theme2::after {
    color: #c8feeb;
  }

  .bg-theme {
    background: #C8FEEB;
  }
}

.column-color5 {
  a {
    color: #7F988C;
  }
  .line_dots::after {
    color : #7F988C;
  }
  .bg-column {
    background-color: #7F988C;
  }
  .bottom-title {
    background: #ffee98;
  }
  .post-adv a{
    color: #ffee98;
  }
  .color-theme {
    color: #7F988C;
  }
  .color-theme2, .color-theme2::after {
    color: #ffee98;
  }

  .bg-theme {
    background: #ffee98;
  }
}

.column-color6 {
  a {
    color: #FF7373;
  }
  .line_dots::after {
    color : #FF7373;
  }
  .bg-column {
    background-color: #8CD0EC;
  }
  .bottom-title {
    background: #ffee98;
  }
  .post-adv a{
    color: #FF7373;
  }
  .color-theme {
    color: #FF7373;
  }
  .color-theme2, .color-theme2::after {
    color: #FF7373;
  }

  .bg-theme {
    background: #FF7373;
  }

  .line_dots2::after {
    color : #ffee98;
  }
}

.column-color7 {
  a {
    color: #FF7373;
  }
  .line_dots::after {
    color : #FF7373;
  }
  .menu-inner a {
    color: #333;
  }

  .bg-column {
    background-color: #ddd;
  }
  .bottom-title {
    background: #ffee98;
  }
  .post-adv a{
    color: #FF7373;
  }
  .color-theme {
    color: #FF7373;
  }
  .color-theme2, .color-theme2::after {
    color: #FF7373;
  }

  .bg-theme {
    background: #FF7373;
  }

  .line_dots2::after {
    color : #ffee98;
  }
}

.column-color8 {
  a {
    color: #669fcf;
  }
  .menu-inner a {
    color: #333;
  }
  .line_dots::after {
    color: #669fcf;
  }
  .bg-column {
    background-color: #f6f6f6;
  }
  .bottom-title {
    background: #669fcf;
  }
  .color-theme {
    color: #669fcf;
  }

  .bg-theme {
    color: #80aed5;
  }

}

// ↑ここまで各デザインの色設定

.mokuji-color-white a{
  color: white !important;
}

.mokuji-color-dark-gray a{
  color: #333 !important;
}

//英語コーチング、その裏側
.uragawa-bg1 {
  position: fixed;
  right: 0;
  bottom: 0;
}

.uragawa-bg2 {
  position: fixed;
  left: 0;
  top: 0;
}


// ここから共通css
header .bg-column {
  @include breakpoint(small only) {
    padding-top: 0;
  }
}
.urban-column {
  // タイトル
  .post-title {
    border-bottom: 2px solid #fff;
    border-top: 2px solid #fff;
    line-height: 2.4;
  }
  // タイトル角丸
  .post-title-circle {
    border-radius: 30px;
    line-height: 2;
    padding: 10px;
  }

  @include breakpoint(medium up) {
    h1, h2 {
      font-weight: 900;
      font-size: 30px;
    }
  }

  // コラムメイン
  .post-main {
    border-radius: 10px;
    padding: 40px 20px;
    @include breakpoint(medium up) {
      border-radius: 30px;
      padding: 60px 90px;
    }
    p {
      line-height: 1.9;
      margin-bottom: 30px;
      @include breakpoint(small only) {
        font-size: 15px;
      }
    }
  }

  .side-padding {
    padding-left: 20px;
    padding-right: 20px;
    @include breakpoint(medium up) {
      padding-left: 90px;
      padding-right: 90px;
    }
  }

  .max-800 {
    max-width: 670px;
  }

  .mokuji a, .mokuji p{
    margin-bottom: 10px;
    @include breakpoint(small only) {
      font-size: 15px;
    }
  }
  .mokuji-white a, .mokuji-white h4{
    color: white !important;
  }

  .mokuji-dark-gray a, .mokuji-dark-gray h4{
    color: #333 !important;
  }

  h3.bottom-title {
    border-radius: 30px;
  }
}
