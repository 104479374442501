// 色関係

$off-white : #f8ffee;
$color-primary2 : #008080;
$dark-gray2 : #fafafa;
$color-index : ivory;

$color-kyomo1 : #82636b;
$color-kyomo2 : #D47475;

$color-anata1 : #E27D61;
$color-anata2 : #f79e6c;
$color-anata3 : #fcfcf2;

$color-bokura1 : #F9C023;
$color-bokura2 : #FEE99C;
$color-bokura3 : #437EB0;
$color-bokura4 : #F2F4F5;

.color-blue1 {
  color: #4770B9;
}

.bg-blue1 {
  background-color: #306DC6;
}

.bg-blue2 {
  background-color: #C4DEFF;
}

.bg-blue3 {
  background-color: #E6F1FF;
}

.bg-blue4 {
  background-color: #D9EFF4;
}

.bg-blue5 {
  background-color: #EBF6F9;
}

.bg-orange1 {
  background-color: #f9dfd3;
}

.color-white {
  color: white !important;
}

.color-black{
  color: $black;
}

.color-dark-gray{
  color: #333;
}

.color-dark-gray2 {
  color: #585656;
}

.color-primary {
  color: $primary-color;
}

.color-primary2 {
  color: $color-primary2;
}

.color-index {
  color: $color-index;
}

.color-anata1 {
  color: $color-anata1;
}

.color-anata2 {
  color: $color-anata2;
}

.bg-anata1 {
  background: $color-anata1;
}

.bg-anata2 {
  background: $color-anata2;
}

.bg-anata3 {
  background: $color-anata3;
}

.bg-kyomo1 {
  background: $color-kyomo1;
}

.bg-kyomo2 {
  background: $color-kyomo2;
}

.bg-white {
  background: white !important;
}

.bg-hazy {
  background: rgba(255, 255, 255, .85);
}

.bg-hazy2 {
  background: rgba(255, 255, 255, .6);
}

.bg-hazy3 {
  background: rgba(0, 0, 0, .9) !important;
}

.bg-hazy4 {
  background: rgba(150, 150, 150, .25)
}

.bg-primary {
  background: $primary-color;
}

.bg-primary2 {
  background: $color-primary2;
}

.bg-index {
  background: $color-index;
}

.bg-dark-white {
  background: #fbfbfb;
}

.bg-dark-gray2 {
  background: $dark-gray2;
}

.bg-dark-gray3 {
  background: #ddd;
}

.bg-off-white {
  background: $off-white;
}

.bg-dark-gray {
  background: #585656;
}

.bg-bokura1 {
  background: $color-bokura1;
}

.bg-bokura2 {
  background: $color-bokura2;
}

.bg-bokura3 {
  background: #FFF8DC;
}

.bg-bokura4 {
  background: $color-bokura4;
}

.bg-bokura5 {
  background: #F8F8FF;
}

.bg-bokura6 {
  background: #FDF9F6;
}

.color-bokura1 {
  color: $color-bokura1;
}

.color-bokura2 {
  color: $color-bokura2;
}

.color-shunkan1 {
  color: #020732;
}

.color-shunkan2 {
  color: #0379BF;
}

.bg-navy {
  background: #0f223d;
}

.bg-yellow {
  background: #fdedac;
}
