.skippr {
	width:100%;
	height:100%;
	position: relative;
	overflow:hidden;
}

.skippr > div {
	position: absolute;
	width:100%;
	height:100%;
	background-size: cover;
	background-position: 50% 62.5%;

}

.skippr > img {
	position: absolute;
	top:50%;
	left:50%;
	-webkit-transform:translate(-50%,-50%);
	-moz-transform:translate(-50%,-50%);
	-ms-transform:translate(-50%,-50%);
	transform:translate(-50%,-50%);
	width:100%;
	min-height: 100%;
}

.skippr-nav-container {
	display: none;
	position: absolute;
	left:50%;
	-webkit-transform:translate(-50%,0);
	transform:translate(-50%,0);
	-moz-transform:translate(-50%,0);
	-ms-transform:translate(-50%,0);

	bottom:25px;
	overflow: auto;
	z-index:999;

}

.skippr-nav-element {
	cursor:pointer;
	float:left;
	background-color: rgba(255,255,255,0.5);
	-webkit-transition: all .25s linear;
	transition: all .25s linear;
}

.skippr-nav-element-bubble {
	width:12px;
	height:12px;
	border-radius: 50%;
	margin:0 4px;
}

.skippr-nav-element-block {
	width:60px;
	height:10px;
}



.skippr-nav-element:hover, .skippr-nav-element-active {
	background-color:rgba(255,255,255,1);
}


.skippr-arrow {
	position: absolute;
	z-index:999;
	top:50%;
	-webkit-transform:translate(0%,-50%);
	-moz-transform:translate(0%,-50%);
	-ms-transform:translate(0%,-50%);
	transform:translate(0%,-50%);

	width:30px;
	height:30px;
	/*background-color: white;*/
	transform-origin:center center;
	-webkit-transform-origin:top left;
	cursor: pointer;
}

.skippr-previous {
	left:2.5%;
	-webkit-transform:rotate(-45deg);
	transform:rotate(-45deg);
	border-top:2px solid #333;
	border-left:2px solid #333;
}

.skippr-next {
	right:3.5%;
	-webkit-transform:rotate(-45deg);
	transform:rotate(-45deg);
	border-bottom:2px solid #333;
	border-right:2px solid #333;
}
