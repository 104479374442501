
// topに戻るボタン
.top-button-outer {
  cursor: pointer;
  bottom: 10px;
  right: 10px;
}

.top-button {
  width: 80px;
  height: 80px;
  @include breakpoint(small only) {
    width: 60px;
    height: 60px;
  }
}

// タイトルの点線
// タイトルの点線は、タイトル要素となるh2,h3などに .title, その中身に .title-innerをつける。
.title {
  z-index: 2;
  position: relative;
  text-align: center;
}

.title-inner {
  padding: 0 14px;
}

.title::after {
  content:"";
  position: absolute;
  top: 48%;
  left: 0;
  border-bottom: 2px dotted $primary-color;
  width: 100%;
  z-index: -1;
}

// タイトルの点線ここまで

// ３つの点
.line_dots {
  border: none;
  position: relative;
  height: 10px;
  display: block;
  margin-bottom: 40px;
}

.line_dots::after {
  content:"●　●　●";
  font-weight: bold;
  text-align: center;
  position: absolute;
  top: -5px;
  left: 0;
  width: 100%;
}
// ３つの点ここまで

// index-icon
.index-icon-left {
  border-top-left-radius: 66px;
  border-top-right-radius: 66px;
  width: 66px;
  margin-left: 6px;
  padding-top: 10px;
  height: 34px;
  z-index: 10;
}

.index-icon-center {
  border-top-left-radius: 160px;
  border-top-right-radius: 160px;
  width: 130px;
  padding-top: 6px;
  height: 40px;
  z-index: 10;
  position: absolute;
  top: -40px;
  left: 0;
  right: 0;
  margin: auto;
}
// index-icon ここまで

// ホバーすると白くなるアクション
a, button, .link, .modal-switch {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

a:not(.non-deco):hover::after, button:hover::after, .link:hover::after, .modal-switch:hover::after {
  content: "";
  display: block;
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: rgba(255, 255, 255, .4);
  height: 100%;
  transition: .3s;
}
// ホバーすると白くなるアクションここまで

// new のアイコン
.new {
  position: relative;
}

.new::before {
  position: absolute;
  font-weight: bold;
  color: $color-bokura1;
  font-family: "jaf-domus-titling-web";
  top: -15px;
  right: -3px;
  z-index: 10;
  content: "new";
}

// Youtube埋め込み
.youtube-frame {
  width: 100%;
  aspect-ratio: 16 / 9;
}
.youtube-frame iframe {
  width: 100%;
  height: 100%;
}

// ------------パーツここまで

// 表示系
.pointer {
  cursor: pointer;
}

.trn03 {
  transition: all .3s ease;
}

.radius{
  border-radius: $global-radius;
}

.radius2{
  border-radius: 8px;
}

.radius12 {
  border-radius: 12px;
}

.radius20 {
  border-radius: 20px;
}

.radius30 {
  border-radius: 30px;
}

.border0 {
  border: none !important;
}

.border1 {
  border: 1px solid #bba;
}

.border2 {
  border: 2px solid #555;
}

.border3 {
  border: 0.6px solid #ccc;
}

.border4 {
  border: 0.6px solid #aaa;
}

.border5 {
  border: 1px solid #aaa;
}

.border-bottom {
  border-bottom: 1px solid #555;
}

.border-bottom2 {
  border-bottom: 1px solid #ddd;
}

.border-top {
  border-top: 1px solid #555;
}

.table-border {
  border-collapse: collapse;

  td, th {
    padding: 2px 10px;
    border: 1px solid #ddd;
  }
}

.table-border2 {
  border-collapse: collapse;

  td, th {
    padding: 6px 12px;
    border: 1px solid #ddd;
  }
}

.table-border3 {
  td, th {
    padding: 2px 4px;
  }
}

.table-border4 {
  border-collapse: collapse;

  td, th {
    padding: 10px 20px;
    border: 1px solid #ddd;
  }
}

.table-border5 {
  border-collapse: collapse;

  td, th {
    padding: 10px 20px;
    border: 1px solid #aaa;
  }
}


.table150 td {
  width: 150px;
}

.hidden {
  overflow: hidden;
}

.scroll-y {
  overflow-y: scroll;
}

.scroll-x {
  overflow-x: scroll;
  overflow-scrolling: touch;
}

.block {
 display: block;
}

.inline-block {
 display: inline-block;
}

.inline {
  display: inline;
}

.none {
  display: none;
}

.visible {
  visibility: visible;
}

.invisible {
  visibility: hidden;
  opacity: 0;
}

.radius-bottom {
  border-radius: 0 0 $global-radius $global-radius;
}

.img-circle {
  border-radius: 50%;
}

.radius-theme-small {
  border-radius: 140px 15px 105px 15px/15px 95px 15px 155px;
}

.radius-theme {
  border-radius: 255px 15px 225px 15px/15px 225px 15px 255px;
}

.boot-fadein { //起動時に 浮かび上がるアイテム
  opacity: 0;
}

.show-less-medium {
  @include breakpoint(large up) {
    display: none !important;
  }
}

.cover-dots::after {
  content: "";
  z-index: 2;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  margin: auto;
  display: block;
  width: 100%;
  height: 100%;
  background-image: url(../img/bg-pattarn1.png);
  background-repeat: repeat;
  background-size: auto;
  background-position: center;
}

.img-circle.cover-dots::after {
  border-radius: 50%;
}

// ボタン
button {
  border-radius: 4px;
  padding:12px;
  cursor: pointer;
}

button.hollow {
  border: 1px solid $primary-color;
  color: $primary-color;
}

button.hollow-color2 {
  border: 1px solid $color-anata1;
  color: $color-anata1;
}

button.button-radius {
  border-radius: 20px;
}

button.button-small {
  padding: 6px 12px 7px !important;
}

button.button-circle {
  width: 100px;
  height: 100px;
}
// ボタンここまで


// ポジショニング系
.z-0 {
  z-index: 0;
}

.z-1 {
  z-index: 1;
}

.z-10 {
  z-index: 10;
}

.z-100 {
  z-index: 100;
}

.z-n1 {
  z-index: -1;
}

 .relative {
   position: relative;
 }

 .absolute {
   position: absolute;
 }

 .sticky {
   position: sticky;
 }

 .absolute-center {
   position: absolute;
   margin: auto;
   top: 0;
   left: 0;
   right: 0;
   bottom: 0;
 }

 .fixed {
   position: fixed !important;
 }

 .flex {
   display: flex;
   flex-flow: inherit;
 }

.float-left {
  float: left;
  margin-right: 16px;
}

.float-right {
  float: right;
}

.flex-reverse {
    flex-flow: row-reverse;
}

.flex-reverse2 { //small-12を付ける場合は medium以上でのみ効果あるようにする
  @include breakpoint(medium up) {
    flex-flow: row-reverse;
  }
}

.flex-reverse3 { //small-12を付ける場合は medium以上でのみ効果あるようにする
  @include breakpoint(large up) {
    flex-flow: row-reverse;
  }
}


// ノート風の掛線
.border-note {
  border-bottom: .5px #ddd solid;
  padding-top: 5px;
  padding-bottom: 5px;
}

/* font-size = 16px, line-height = 1.5 の場合 */
.border-note2 {
  padding-top: 0;
  padding-bottom: 0;
  background: repeating-linear-gradient(rgba(255,255,255,0) 0, rgba(255,255,255,0) 31px, #aaa 31px, #aaa 32px);
}

.border-note2 p {
  margin-top: 0;
  padding-left: 4px;
  line-height: 2;
  font-size: 16px;
  margin-bottom: 32px;
}

// ポジショニングここまで


// 文字組、文字のデコレーション
.text-align-center{
  text-align: center;
}

.text-align-left{
  text-align: left;
}

.text-align-right {
  text-align: right;
}

.img-center {
  display: block;
  margin: auto;
}

.font-family-YuGothic body {
  font-family: "游ゴシック", YuGothic, Lato, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
}

.font-family-YuGothic-important {
  font-family: "游ゴシック", YuGothic, Lato, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif !important;
}

.font-size-10 {
  font-size: 12px;
}

.font-size-12 {
  font-size: 12px;
}

.font-size-13 {
  font-size: 13px;
}

.font-size-14 {
  font-size: 14px;
}

.font-size-15 {
  font-size: 15px;
}

.font-size-16 {
  font-size: 16px;
}

.font-size-18 {
  font-size: 18px;
}

.font-size-20 {
  font-size: 20px;
}

.font-normal {
  font-size: 16px;
  font-weight: normal;
}

.font-size-24 {
  font-size: 24px;
  font-weight: 700;
}

.line-height2 {
  line-height: 2;
}

.line-height16 {
  line-height: 1.6;
}

.line-height18 {
  line-height: 1.8;
}

.bold {
  font-weight: bold;
}

.under_line {
  text-decoration: underline;
}

.font-domus {
  font-family: "jaf-domus-titling-web";
}

.font-marugo {
  font-family: "dnp-shuei-mgothic-std";
}

.white_line {
  display: inline-block;
  padding: 0 6px;
  width: auto;
  background:rgba(0, 0, 0, 0) linear-gradient(transparent 70%, #ffffff 0%) repeat scroll 0 0;
}

.yellow_line {
  display: inline-block;
  padding: 0 6px;
  width: auto;
  background:rgba(0, 0, 0, 0) linear-gradient(transparent 70%, #FFF093 0%) repeat scroll 0 0;
}

.peach_line {
  display: inline-block;
  padding: 0 6px;
  width: auto;
  background:rgba(0, 0, 0, 0) linear-gradient(transparent 75%, #EFAB8C 0%) repeat scroll 0 0;
}

.blue_line {
  display: inline-block;
  padding: 0 6px;
  width: auto;
  background:rgba(0, 0, 0, 0) linear-gradient(transparent 75%, #CCE7DF 0%) repeat scroll 0 0;
}
//文字組、文字のデコレーションここまで


// シャドウ
.modal-shadow {
  filter: drop-shadow(0 0 5px rgba(0,0,0,0.2));
}

.modal-shadow2 {
  filter: drop-shadow(0 0 15px rgba(0,0,0,0.6));
}

.box-shadow {
  box-shadow: rgba(0, 0, 0, .15)0 6px 2px -2px;
}

.box-shadow3 {
  box-shadow: rgba(0, 0, 0, .15)0 3px 2px -2px;
}

.box-shadow2 {
  filter: drop-shadow(0 0 2px rgba(0,0,0,0.2));
}

.text-shadow {
  text-shadow: -1.3px -1px 5px #fff;
}

.fade-shadow1 {
  mask-image: linear-gradient(180deg, rgba(0,0,0,1) 60%, rgba(0,0,0,0) 96%);
}
// シャドウここまで


// width と height
.width100 {
  width: 100%;
}

.width-auto {
  width: auto;
}

.height100 {
  height: 100%;
}

.height100v {
  height: 100vh;
}
// width と heightここまで


// max-width
.max-1180 {
  max-width: 1180px;
}

.max-1010 {
  max-width: 1010px;
}

.max-960 {
  max-width: 960px;
}

.max-820 {
  max-width: 800px;
}

.max-800 {
  max-width: 800px;
}

.max-720 {
  max-width: 720px;
}

.max-640 {
  max-width: 640px;
}
// max-widthここまで


//マージンの設定
.margin0 {
  margin: 0 !important;
}

.margin-auto {
  margin: auto;
}

.margin-bottom-small{
  margin-bottom: $margin-small;
}
.margin-bottom-medium{
  margin-bottom: $margin-medium;
}
.margin-bottom-large{
  margin-bottom: $margin-large;
}
.margin-bottom-xlarge{
  margin-bottom: $margin-xlarge;
}
.margin-bottom-xxlarge{
  margin-bottom: 90px;
}
.margin-bottom-xxxlarge{
  margin-bottom: 140px;
}

.margin-top-small{
  margin-top: $margin-small;
}
.margin-top-medium{
  margin-top: $margin-medium;
}
.margin-top-large{
  margin-top: $margin-large;
}
.margin-top-xlarge{
  margin-top: $margin-xlarge;
}
.margin-side {
  margin-left: $margin-small;
  margin-right: $margin-small;
}

.margin-side-small {
  margin-left: .375rem;
  margin-right: .375rem;
}

.margin-bottom-small-neg{
  margin-bottom: -$margin-small;
}
.margin-bottom-medium-neg{
  margin-bottom: -$margin-medium;
}
.margin-bottom-large-neg{
  margin-bottom: -$margin-large;
}
.margin-bottom-xlarge-neg{
  margin-bottom: -$margin-xlarge;
}

// パディングの設定
.padding0 {
  padding: 0 !important;
}

.padding-small {
  padding: $margin-small;
}

.padding-medium {
  padding: $margin-medium;
}

.padding-bottom-small{
  padding-bottom: $margin-small;
}
.padding-bottom-medium{
  padding-bottom: $margin-medium;
}
.padding-bottom-large{
  padding-bottom: $margin-large;
}
.padding-bottom-xlarge{
  padding-bottom: $margin-xlarge;
}

.padding-top-small{
  padding-top: $margin-small;
}
.padding-top-medium{
  padding-top: $margin-medium;
}
.padding-top-large{
  padding-top: $margin-large;
}
.padding-top-xlarge {
  padding-top:  $margin-xlarge;
}

.padding-side-small2 {
  padding-left: 6px;
  padding-right: 6px;
}

.padding-side-small {
    padding-left: $margin-small;
    padding-right: $margin-small;
}
.padding-side-medium {
    padding-left: $margin-medium;
    padding-right: $margin-medium;
}
.padding-side-large {
    padding-left: $margin-large;
    padding-right: $margin-large;
}
