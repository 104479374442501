/**
  既存デザインの上書き
 */

body {
  overflow-scrolling: touch;
}

img {
  -webkit-user-drag:none;
  -khtml-user-drag:none;
}

ul{
  margin: 0;
}

li{
  list-style: none;
}

h1,h2,h3,h4,h5,h6 {
  display:block;
  width:100%;
  margin: 0;
}

h2 {
  font-weight: 900;
}

h3 {
  font-size: 22px;
  font-weight: 700;
}

h4 {
  font-weight: 700;
}

button {
  font-family: inherit;
}

// fullCalendar
.fc-view table {
  border-collapse: separate;
  border-spacing: 3px;
}

.fc td {
  border: 0;
}

td.fc-day {
  border-top: 1px solid #333;
}

.fc th {
  padding: 4px 0;
}

.fc-head-container, .fc-widget-header {
  border: 0 !important;
}

.fc-ltr .fc-basic-view .fc-day-top .fc-day-number {
  float: left;
}

.fc-day-grid-event .fc-content {
  white-space: normal;
}

textarea {
  height: 150px;
}
