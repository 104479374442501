
iframe {
  max-width: 100%;
}

.article-hitokoto {
  position: absolute;
  bottom: 11%;
  right: 20px;
  @include breakpoint(medium up) {
    right: 30%;
  }
}

.bokura-column {

  a {
    word-break: break-all;
  }

  body, h1, h2, h3, h4, h5, h6, p, span, {
    font-family: "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
    color: black;
  }

  .column-inner {
    padding: 30px 0;

    @include breakpoint(medium up) {
      padding: 70px 0;
    }

    @include breakpoint(large up) {
      padding: 80px 0;
    }
  }

  p, span, table, li{
    font-size: 15px;
    line-height: 2;

    @include breakpoint(medium up) {
      font-size: 16px;
    }
  }

  #index-sidebar {
    position: sticky;
    top: 14px;

    li {
      font-size: 14px;
    }

    a {
      color: #333;
    }
  }

  h1, h2, h3, h4, h5 {
    font-weight: 900;
    line-height: 1.8;
  }

  h1 {
    font-size: 24px;
    @include breakpoint(medium up) {
      font-size: 30px;
    }
  }

  h2 {
    font-size: 20px;

    @include breakpoint(medium up) {
      font-size: 24px;
    }

    margin: 60px 0 30px;
    border-left: 5px solid #F9C024;
    border-bottom: 1px solid #F9C024;
    padding: 11px 14px 11px 20px;
  }

  h3 {
    font-size: 20px;
    margin: 50px 0 20px;
    padding: 6px;
    padding-left: 10px;
    border-left: 3px solid #b3e0e7;
  }

  h4 {
    font-size: 16px;
    margin: 40px 0 12px;
  }

  h4:not(.simple) {
    border-radius: 4px;
    padding: 6px 10px;
    background: #EFF8FF;
  }

  h4.yellow {
    background: #fff4ce;
  }

  h4.orange {
    background: #f9dfd3;
  }

  h5 {
    font-size: 16px;
    font-weight: 700;
    margin: 30px 0 12px;
  }

  .deco {
    padding: 3px;
    padding-left: 8px;
    border-left: 3px solid #ddd;
  }

  .comment {
    font-size: 13px;
    color: #555;
    line-height: 1.5;
  }

  .comment2 {
    font-size: 14px;
    color: #555;
    line-height: 1.5;
  }


  .tips {
    border-radius: 8px;
    padding: 16px;
    background-color: #F7F9F9;
    margin-bottom: 16px;

    h2, h3, h4, h5, h6 {
      margin-top: 0;
    }
  }

  .tips2 {
    border-radius: 8px;
    padding: 16px;
    background-color: #FFF8DC;
    margin-bottom: 16px;

    h2, h3, h4, h5, h6 {
      margin-top: 0;
    }
  }

  .tips3 {
    border-radius: 8px;
    padding: 16px;
    background-color: #e6f5f8;
    margin-bottom: 16px;

    h2, h3, h4, h5, h6 {
      margin-top: 0;
    }
  }

  .tips4 {
    border-radius: 8px;
    padding: 16px;
    margin-bottom: 16px;
    border: 0.7px solid #aaa;

    h2, h3, h4, h5, h6 {
      margin-top: 0;
    }
  }
}

.index {
  border-radius: 8px;
  padding: 16px;
  background-color: #F7F9F9;
  margin-bottom: 16px;

  h2, h3, h4, h5, h6 {
    margin-top: 0;
  }

  h2 {
    font-size: 16px;
    font-weight: 900;
    margin: 0 0 10px;
    border: none;
    padding: 0;
  }

  li {
    border-bottom: 1px solid #ccc;
    padding: 4.5px;
    font-size: 14px;
    @include breakpoint(medium up) {
      font-size: 15px;
    }
  }

  a {
    color: #555;
  }
}

// 瞬間英作文デモ
.sakubun-answer {
  display: none;

  .comment {
    line-height: 1.2;
  }
}

.sakubun-answer.active {
  display: block;
}

// リスニングデモ

.listen-question {
  cursor: pointer;

  span {
    opacity: 0;
  }

  .listen-question-answer, .listen-question-answer-not-play {
    float: right;
    border-radius: 4px;
    text-align: center;
    font-size: 15px;
    font-weight: bold;
  }
}

.listen-question-border {
  height: 10px;
  max-width: 280px;
  border-bottom: 1px solid #999;
}

.listen-question.answered {
  span {
    opacity: 1;
  }

  .listen-question-border {
    display: none;
  }
}
