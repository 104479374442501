// ヘッダー
.header-logo img{
  width: 280px;
  @include breakpoint(medium up) {
    width: 420px;
  }
}

.hero h2 {
  font-size: 32px;
  @include breakpoint(medium down) {
    text-align: center;
    width: 100%;
    font-size: 24px;
  }
}

.hero-img {
  @include breakpoint(medium up) {
    width: 90%;
  }
}

// ------ 各セクション ------------

.top_menu_icon {
  width: 26px;
  vertical-align: bottom;
  margin-right: 4px;
}

section{
  position: relative;
  padding: 60px 0 60px;
  .section-title {
    font-size: 90px;
    line-height: 1;
  }

  @include breakpoint(small only) {
    padding: 28px 0;
  }
}

.section-service {
  @include breakpoint(small only) {
    h2 {
      font-size: 30px;
    }
  }
}

.index-bokura {
  background-image: url('../img/bg-pattarn1.png'),url('../img/bokura_hero_back.webp');
  background-repeat: repeat, no-repeat;
  background-size: auto, cover; background-position: top;

  .index-bokura-inner {
    margin: 80px 0;
    @include breakpoint(small only) {
      margin: 120px 0 60px;
    }
  }
}

.no-webp .index-bokura {
  background-image: url('../img/bg-pattarn1.png'),url('../img/bokura_hero_back.jpg');
}

.index-shunkan-right {
  @include breakpoint(large up) {
    margin-top: -32px;
    padding-left: 20px;
  }
}

.index-eitango {
  @include breakpoint(small only) {
    padding-bottom: 180px;
  }
}

.index-eitango-content-image {
  right: 10px;
  bottom: -40px;
  height: 320px;
  transform: rotate(-4deg);

  @include breakpoint(medium up) {
    right: 30px;
    height: 500px;
    bottom: -150px;
  }
}
