//モーダル
.modal-outer {
	display: none;
	position: fixed;
	cursor: pointer;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(255, 255, 255, .9);
	z-index: 10000;
  overflow-y: scroll;
}

.modal-body {
	cursor: default;
	position: relative;
  background: white;
  border: 1px solid $primary-color;
	max-width: 800px;
	width: 100%;
	margin: $margin-large auto;
	padding: $margin-large 20px;
	z-index: 30;
  @include breakpoint(small only) {
    padding: $margin-medium 10px;
  }
}

.modal-close {
  font-size: 26px;
  position: absolute;
  right: 10px;
  top: 10px;
	z-index: 100000;
  .modal-close-button {
  	cursor: pointer;
    width: 42px;
    height: 42px;
    padding-top: 4px;
    text-align: center;
  }
  @include breakpoint(medium up) {
    font-size: 28px;
  }
}

.modal-open {
	cursor: pointer;
  width: 100%;
}

.modal-outer-success {
  background: rgba(255, 255, 255, .8);
  z-index: 15000;
  .modal-body {
    width: 300px;
    margin: 0;
    margin-top: 100px;
    border-radius: 5px;
    border: 2px $primary-color solid;
    background: #fff;
  }
  .modal-close {
    position: absolute;
    right: 15px;
    top: 10px;
    .modal-close-button {
      width: auto;
      background: none;
    }
  }
  h4 {
    font-size: 16px;
  }
}

// Menu ----------------------------

.menu {
  position: fixed;
  z-index: 4000;
  width: 100%;
  top: 0;
}

.general-menu {
  position: absolute;
  top: 6px;
  right: 17px;
}

.menu-button {
	background: rgba(255, 255, 255, .9);
	padding: 2px 3px;
}

.menu-inner {
  width: 100%;
  height: 100%;
  background: white;
  z-index: 80000;
  position: fixed;
  top: 0;
  right: 0;
  li {
    display: block;
    padding: 8px 0;
    border-bottom: 1px solid #585656;
  }
  a {
    color: #585656;
  }
  img {
    max-height: 50px;
  }
  @include breakpoint(medium up) {
    width: 50%;
    max-width: 360px;
  }
}

// header footer ----------------------------

.umt-logo {
	width: 120px;
	margin-right: 60px;
	margin-top: 10px;
}

.footer-menu a {
  color: white !important;
}

.sns {
  max-width: 100%;
  overflow: hidden;
}

.grecaptcha-badge {
	visibility: hidden;
}
