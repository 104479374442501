.bokura-border1 {
  border: 1px solid $color-bokura3;
}

.bokura-border2 {
  border: 2px solid $color-bokura3;
}

.bokura-border3 {
  border: 2px solid $color-bokura1;
}

.bokura-border4 {
  border: 2px solid #020732;
}

// hero
.bokura-hero {
  @include breakpoint(large up) { //大枠は、PCサイズで100vh
    height: 100vh;
  }
}

.bokura-hero1 {
  @include breakpoint(medium down) { //タブレット以下は、hero1を100vh
    height: 97vh;
  }
}

.bokura-hero2 {
  transform: skewY(-6.6deg);
}

// - -- -右左コンテンツの切り替え - - -

.bokura-main-outer {
  transition: all 1s ease;
  margin-left: 0;
}

.bokura-main-outer.right { //右側を表示
  margin-left: -100%;
}

.bokura-content-center, .bokura-content-right {
  transition: all .2s ease;
}

// -------チャット

/*チャット用のCSS*/

.chat-outer {
  position: fixed;
  bottom: 0;
  right: 5px;
  width: 280px;
}

.listen-book-request-outer {
  position: fixed;
  bottom: 0;
  right: 5px;
  width: 320px;
}

.chat-inner {
  height: 100%;
  padding: 10px;
}

// h2 タイトル
.bokura-title {
  z-index: 10;
  border-radius: 30px;
  width: auto;
  @include breakpoint(small only) {
    font-size: 18px;
    margin:auto auto -20px;
  }
  @include breakpoint(medium up) {
    margin-bottom: -25px;
  }
}

.bokura-content {
  @include breakpoint(small only) {
    padding-top: 50px;
  }
  @include breakpoint(medium up) {
    padding-top: 70px;
  }
}

// 募集期間
.apply-due {
  z-index: 10000;
  position: fixed;
  bottom: 0;
  left: 0;
  margin-bottom: 0;
}

// メニュー
.bokura .menu {
  z-index: 90;
}
.bokura .lang-button {
  display: none;
}

.bokura .color-primary {
  color: $color-bokura1;
}

.bokura .modal-body {
  border: 2px solid $color-bokura1;
}

// bokura online
.online-h2 {
  font-size: 24px;
  @include breakpoint(medium up) {
    font-size: 28px;
  }
}

.online-schedule-bar {
  width: 10px;
  height: 100%;
  top: 0;
  left: 12px;
  z-index: 0;
  background: #DAF0F5;
  @include breakpoint(medium up) {
    left: 0;
  }
}

.online-schedule {
  position: absolute;
  z-index: 10;
  padding: 4px 10px;
  top: -30px;
  left: -4px;
  @include breakpoint(medium up) {
    top: 0px;
    left: -30px;
  }
}

// つながる英単語
.eitango-font {
  body, h1, h2, h3, h4, h5, h6 {
    font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", "Helvetica Neue", HelveticaNeue, YuGothic, "Yu Gothic Medium", "Yu Gothic", Verdana, Meiryo, sans-serif;
  }
}

.eitango-content1 {
  position: relative;
  background: #C4DEFF;
  padding: 74px 0;

  @include breakpoint(medium up) {
    padding: 120px 60px 80px;
  }
  @include breakpoint(large up) {
    border-radius: 32px 0 0 32px;
  }
}

.eitango-content1::before {
  content: "";
  background: #C4DEFF;
  position: absolute;
  top: 0;
  right: -2000px;
  height: 100%;
  width: 2000px;
}

// つながる英単語
.eitango-content2 {
  position: relative;
  background: #F9DFD3;
  padding: 74px 0;

  @include breakpoint(medium up) {
    padding: 120px 60px 100px;
  }
  @include breakpoint(large up) {
    border-radius: 0 32px 32px 0;
  }
}

.eitango-content2::before {
  content: "";
  background: #F9DFD3;
  position: absolute;
  top: 0;
  left: -2000px;
  height: 100%;
  width: 2000px;
}

.eitango-content3 {
  @include breakpoint(small down) {
    padding-bottom: 320px;
  }
}

.eitango-content-image {
  right: 0;
  left: 0;
  bottom: -40px;
  height: 320px;
  transform: rotate(-4deg);

  @include breakpoint(medium up) {
    height: 430px;
    right: -10px;
    left: auto;
    bottom: -70px;
  }
}

.eitango-content-right {
  margin-bottom: 40px;

  @include breakpoint(large up) {
    padding-left: 60px;
    padding-right: 0;
    margin-bottom: 0;
  }
}

.eitango-content-left {
  margin-bottom: 40px;

  @include breakpoint(large up) {
    padding-right: 60px;
    padding-left: 0;
    margin-bottom: 0;
  }
}

.eitango_demo_buttom {
  display: none;
}

.eitango_demo_question.answered {
  .eitango_demo_buttom {
    display: block;
  }

  .eitango_demo_eng {
    display: none;
  }

  .eitango_demo_jap {
    display: block;
  }
}

// つながる英単語picker
.picker-bg {
  background: linear-gradient(#f3fafc, #D9EFF4);
}

.picker-title {
  font-size: 34px;
  @include breakpoint(medium up) {
    font-size: 50px;
  }
}

.picker-h2 {
  font-size: 30px;
  @include breakpoint(medium up) {
    font-size: 50px;
  }
}

.picker-demo-video {
  position: absolute;
  z-index: 100;
  width: 85.7%;
  top: 7.4%;
  left: 7.1%;
}

// 単語力チェック
.eitango-check {
  h1 {
    font-size: 50px;
    @include breakpoint(medium up) {
      font-size: 80px;
    }
  }

  h2 {
    font-size: 24px;
    @include breakpoint(medium up) {
      font-size: 26px;
    }
  }

  p {
    font-size: 15px;
    @include breakpoint(medium up) {
      font-size: 16px;
    }
  }
}

.eitango-check-result-column {
  padding: 30px 20px 40px;
  height: 386px;
  @include breakpoint(medium up) {
    padding: 40px 20px;
    height: 300px;
  }

  h2 {
    font-size: 40px;
    @include breakpoint(medium up) {
      font-size: 46px;
    }
  }

  .check-result-img {
    bottom: -30px;
    right: -30px;

    @include breakpoint(medium up) {
      right: -40px;
    }
  }
}

// WPM測定
.wpm-count.active {
  .wpm-count-start {
    display: none;
  }

  .wpm-count-stop {
    display: block !important;
  }

  .wpm-count-mic {
    color: #dd5136 !important;
  }
}

// 瞬間英作文
.shunkan-top-section {
  @include breakpoint(medium up) {
    margin-bottom: 40px;
  }
}

.shunkan-background {
  background-image: url("../img/shunkan_main6.webp");
  background-color:rgba(250,250,250, .9);
  background-blend-mode:lighten;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
}

.shunkan-hero-title {
  position: absolute;
  bottom: 10px;
  right: 20px;
  width: 260px;
  padding: 10px;
  background: rgba(255, 255, 255, .7);
  border-radius: 4px;
  @include breakpoint(medium up) {
    border-radius: 0;
    padding: 0;
    bottom: 30px;
    width: 360px;
    top: auto;
    right: 20px;
    background: none;
  }
  @include breakpoint(large up) {
    width: 480px;
    top: 80px;
    right: -200px;
  }
}

// h2 タイトル
.shunkan-title {
  z-index: 10;
  border-radius: 30px;
  padding: 8px;
  font-size: 18px;
  @include breakpoint(medium up) {
    padding: 10px;
    font-size: 20px;
  }
}

.shunkan-title2 { // 小見出し
  line-height: 1.7;
  font-size: 20px;
  @include breakpoint(medium up) {
    font-size: 24px;
  }
}

.shunkan-p {
  font-weight: 900;
  line-height: 1.8;
  font-size: 16px;
  @include breakpoint(medium up) {
    font-size: 17px;
  }
}

.shunkan-matome-title {
  padding: 66px 10px 20px;

  @include breakpoint(medium up) {
    padding: 70px 10px 40px;
    padding-right: 60px;
  }

  .title-balloon {
    position: absolute;
    top: -50px;
    left: 4px;
    font-size: 16px;

    display: inline-block;
    margin: 0 0 12px;
    padding: 8px 12px;
    min-width: 120px;
    max-width: 100%;
    background: #70c3d5;
    border-radius: 15px;

    @include breakpoint(medium up) {
      padding: 10px 14px;
      top: -54px;
    }
  }

  .title-balloon::before {
    content: "";
    position: absolute;
    top: 100%;
    left: 16%;
    margin-left: -15px;
    border: 6px solid transparent;
    border-top: 8px solid #70c3d5;
  }

  .matome-title-image {
    position: absolute;
    z-index: 2;
    width: 60px;
    bottom: 8px;
    right: 20px;

    @include breakpoint(medium up) {
      width: 80px;
      bottom: -40px;
      right: -100px;
    }
  }
}

.shunkan-top-menu {
  font-size: 16px;
  @include breakpoint(medium up) {
    font-size: 17px;
  }
}

// 吹き出し
.balloon1, .balloon2, .balloon4 {
  position: relative;
  display: inline-block;
  margin: 0 0 12px;
  padding: 10px 14px;
  min-width: 120px;
  max-width: 100%;
  background: $color-bokura2;
  border-radius: 15px;
}

.balloon1::before {
  content: "";
  position: absolute;
  top: 100%;
  left: 16%;
  margin-left: -15px;
  border: 6px solid transparent;
  border-top: 8px solid $color-bokura2;
}

.balloon2:before {
  content: "";
  position: absolute;
  top: 65%;
  right: 0;
  border: 7px solid transparent;
  border-left: 12px solid $color-bokura2;
  margin-right: -16px;
}

.balloon4::before {
  content: "";
  position: absolute;
  bottom: 100%;
  right: 16%;
  margin-left: -15px;
  border: 6px solid transparent;
  border-bottom: 8px solid white;
}

.balloon4 {
  background: white;
}

.balloon3 {
  position: relative;
  border-radius: 15px;
  background: white;
}

.balloon3:after {
  content: "";
  position: absolute;
  top: 65%;
  left: -28px;
  margin-top: -14px;
  border: 14px solid transparent;
  border-right: 14px solid white;
  z-index: 1;
}

.color-bokura4 {
  color: #e9875b;
}

b.color-bokura4 {
  font-weight: 600;
}

.color-bokura5 {
  color: #dd5136;
}
