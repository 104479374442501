// ----- house.php -----------

.head-umt-logo {
  position: absolute;
  left: 0;
  z-index: 1;
  width: 120px;
  margin-right: 60px;
  margin-top: 10px;
}

.hero-catch {
  position: absolute;
  top: 220px;
  right: 0;
  z-index: 2;
  @include breakpoint(small only) {
    width: 230px;
    top: 110px;
    right: auto;
    left: 5px;
  }
}

.house-story {
    display: none;
  @include breakpoint(medium up) {
    display: block;
    width: 300px;
    position: absolute;
    bottom: 0px;
    left: 30px;
    z-index: 1;
  }
}
